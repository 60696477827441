import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import RenderHtml from '../components/RenderHtml';
import { AllHtmlEntities } from 'html-entities';
import Hero from '../components/Hero';
import { graphql, useStaticQuery } from 'gatsby';
import Yoast from '../components/Yoast';

const entities = new AllHtmlEntities();

const About = ({ children, pageContext }) => {
  if (!pageContext) return null;

  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "trucks-hero.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const wpPage = pageContext.wpPage;
  const title = entities.decode(wpPage.title);
  const featuredImage = wpPage.featured_media
    ? wpPage.featured_media.localFile.childImageSharp.fluid
    : data.heroImage.childImageSharp.fluid;

  return (
    <Layout>
      <Yoast title={wpPage.yoast_title} meta={wpPage.yoast_meta} />
      <div className="template--about">
        <Container>
          <h1 className="page-title">{title}</h1>
          <div className="featured-image">
            <Hero image={featuredImage} shadowPosition={25} />
          </div>
          <RenderHtml html={wpPage.content} />
          {children}
        </Container>
      </div>
    </Layout>
  );
};

About.propTypes = {
  children: PropTypes.any,
  pageContext: PropTypes.object,
};

export default About;
