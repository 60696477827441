import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Hero = ({ image, shadowPosition }) => {
  const sectionClasses = [`hero`, `hero--home`];
  if (shadowPosition === 50) {
    sectionClasses.push(`mb-5`);
  }
  if (shadowPosition === 25) {
    sectionClasses.push(`mb-6`);
  }
  return (
    <section className={sectionClasses.join(' ')}>
      <Img fluid={image} />
      <div className={`hero-bg hero-bg--${shadowPosition}`}></div>
    </section>
  );
};

Hero.defaultProps = {
  shadowPosition: 50,
};

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  shadowPosition: PropTypes.number,
};

export default Hero;
